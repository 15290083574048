import { mapState, mapGetters, mapActions } from 'vuex'
import { state } from './modules/wallet'

export const walletComputed = {
    ...mapState('wallet', {
        walletAddress: state => state.address,
        walletBalance: state => state.balance,
        walletChainId: state => state.chainId,
        ethereumDetected: state => state.ethereumDetected,

    }),
    ...mapGetters('wallet', {
        getToken: 'getToken',
        getTokenBalance: 'getTokenBalance'
    })
}

export const walletMethods = mapActions('wallet', {
    setConnected: 'setConnected',
    updateWalletBalance: 'updateBalance',
    connectWallet: 'connect',
    disconnectWallet: 'disconnect',

    // token
    addWalletToken: 'addToken',
    updateWalletTokenBalance: 'updateTokenBalance',
})
