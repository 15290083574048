import axios from 'axios'
import CryptoJS from 'crypto-js'
const log = require('debug')('store:market');
const binanceUrl = 'https://api.binance.com/';
const tickerPath = 'api/v3/ticker/price';

const defaultTickerPairs = ['BTCUSDT', 'BNBUSDT'];

export const state = {
    tickers: [],
}

function getTickerIndex(symbol) {
    for (let i=0; i<state.tickers.length; i++) {
        if (state.tickers[i].symbol == symbol) return i;
    }
    return -1;
}

export const getters = {
    getTicker: (state) => (symbol) => {
        let idx = getTickerIndex(symbol);
        if (idx >= 0) return state.tickers[idx]
        return null;
    },
}

export const mutations = {
    SET_TICKER(state, ticker) {
        let idx = getTickerIndex(ticker.symbol);
        if (idx >= 0) {
            state.tickers.splice(idx, 1, ticker)
        }
        else {
            state.tickers.splice(0, 0, ticker);
        }
    },
}

export const actions = {
    async init({ commit, state, dispatch }) {
        await _updateTickers(commit);
    },
    async updateTickers({ commit, state, dispatch }) {
        await _updateTickers(commit);
    }
}

async function _updateTickers(commit) {
    let url = `${binanceUrl}${tickerPath}`;
    let config = { withCredentials: false };

    for (let i=0; i<defaultTickerPairs.length; i++) {
        try {
            config.params = { symbol: defaultTickerPairs[i] };
            let res = await axios.get(url, config);
            commit('SET_TICKER', res.data)
        }
        catch(e) {
            log(e)
        }
    }
}
